<template>
  <div class="modal-wrap">
    <h1 class="tit-h1 d-flex">
      하위문의유형
      <div class="ml-auto align-self-center">
        <v-icon x-small class="svg-close20" @click="$emit('hide')"></v-icon>
      </div>
    </h1>
    <div class="modal-box">
      <div>
        <div class="">
          <div class="">
            <div>
              <div class="d-flex" v-if="param.childFlag == 'child' || param.childFlag == 'add'">
                <h4 class="mt-3">하위 문의유형명</h4>
                <v-icon color="#EF9191" left>mdi-circle-small</v-icon>
              </div>
              <v-text-field
                  v-if="param.childFlag == 'child' || param.childFlag == 'add'"
                  class="form-inp full"
                  outlined
                  hide-details
                  v-model="txtFieldV"
              ></v-text-field>

              <div class="d-flex">
                <h4 class="mt-3">문의유형 타입</h4>
                <v-icon color="#EF9191" left>mdi-circle-small</v-icon>
              </div>
              <v-select
                  class="form-inp full"
                  :items="dropTypeItems"
                  item-text="CD_NM"
                  item-value="CD"
                  return-object
                  outlined
                  hide-details
                  placeholder="선택하세요"
                  v-model="dropItemsV"
              >
              </v-select>
              <div class="d-flex" v-if="param.childFlag == 'area'">
                <h4 class="mt-3" >문의유형 메시지</h4>
                <v-icon color="#EF9191" left>mdi-circle-small</v-icon>
              </div>
              <v-textarea
                  v-if="param.childFlag == 'area'"
                  class="mt-2"
                  placeholder="문의 유형 메시지"
                  outlined
                  cols="30"
                  rows="10"
                  no-resize
                  v-model="txtAreaV"
                  hide-details
              >
              </v-textarea>
            </div>
            <div class="mt-3">
              <div>
                *<strong class="clr-error">상담사연결형타입</strong>(기본형)
                <p class="text-subtitle-2">하위 문의유형이 있는 경우 하위 문의유형을 출력하고, 없는 경우 상담사에게 연결합니다</p>
              </div>
              <div>
                *<strong class="clr-error">메시지타입</strong>
                <p class="text-subtitle-2">메시지 전송 후 상담이 종료됩니다.</p>
              </div>
              <div>
                *<strong class="clr-error">챗봇연결형타입(카카오톡 전용)</strong>
                <p class="text-subtitle-2">카카오톡 챗봇이 연동된 경우에만 사용 가능하며, 설정시 챗봇 블록으로 이동합니다.</p>
              </div>
            </div>
          </div>
        </div>
        <div class="text-right mt-3">
          <v-btn v-if="this.mixin_set_btn(this.$options.name, 'btnCancle')" outlined class="btn-etc"    @click="popupBtnClose">취소</v-btn>
          <v-btn outlined class="btn-point ml-2" @click="popupBtnDelete" v-if="this.mixin_set_btn(this.$options.name, 'btnDelete') && param.childFlag == 'child'" >삭제</v-btn>
          <v-btn outlined class="btn-default ml-2  " @click="popupBtnModify" v-if="this.mixin_set_btn(this.$options.name, 'btnMody') && param.childFlag != 'add'">수정</v-btn>
          <v-btn outlined class="btn-default ml-2  " @click="popupBtnAdd" v-if="this.mixin_set_btn(this.$options.name, 'btnAdd') && param.childFlag == 'add'">추가</v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mixin} from "@/mixin/mixin";

export default {
  name: "MENU_M810602P01", //name은 'MENU_' + 파일명 조합
  props: {
    param: {
      type: Object,
    },
    headerTitle: {
      type: String,
      default: "문의유형 상세",
    },
  },
  components: {
  //  Ckeditor,
  },
  mixins:[mixin],

  data: () => ({
    txtFieldV    : '',
    dropTypeItems: [],
    dropItemsV   : '',
    txtAreaV     : '',

    footerHideTitle: "닫기",

    gridListSelected: [],

  }),
  methods: {
    popupBtnClose() {
      this.$emit('hide')
    },

    popupBtnDelete() {
      let params = {
        flag : "del",
        sendData : this.param.childIndex,
        childRowIndex: this.param.childRowIndex,
        childColIndex: this.param.childColIndex,
      }
      this.$emit("getPopupData", params);
      this.popupBtnClose();
    },

    popupBtnModify() {
      let params = {};
      if(this.param.childFlag == 'area') {
        if(this.mixin_isEmpty(this.dropItemsV) || this.mixin_isEmpty(this.txtAreaV)) {
          this.common_alert("문의유형타입과 메시지는 필수 입력입니다.", "error")
          return;
        }
        params = {
          flag : "mod",
          cFlag : "area",
          msg : this.txtAreaV,
          inqryType : this.dropItemsV.CD,
          childRowIndex: this.param.childRowIndex,
          childColIndex: this.param.childColIndex,
        }
      }

      if(this.param.childFlag == 'child') {
        if(this.mixin_isEmpty(this.dropItemsV) || this.mixin_isEmpty(this.txtFieldV)) {
          this.common_alert("문의유형명과 타입은 필수 입력입니다.", "error")
          return;
        }
        params = {
          flag : "mod",
          cFlag: "child",
          childIndex: this.param.childIndex,
          childRowIndex: this.param.childRowIndex,
          childColIndex: this.param.childColIndex,
          title     : this.txtFieldV,
          inqryType : this.dropItemsV.CD,
        }
      }

      this.$emit("getPopupData", params);
      this.popupBtnClose();
    },

    popupBtnAdd() {
      if(this.mixin_isEmpty(this.dropItemsV) || this.mixin_isEmpty(this.txtFieldV)) {
        this.common_alert("문의유형명과 타입은 필수 입력입니다.", "error")
        return;
      }
      let params = {
        flag : "add",
        childRowIndex: this.param.childRowIndex,
        childColIndex: this.param.childColIndex,
        childIndex: this.param.childIndex,
        sendData : {
          INQRY_TYP_NM: this.txtFieldV,
          INQRY_TYPE  : this.dropItemsV.CD,
        }
      }
      this.$emit("getPopupData", params);
      this.popupBtnClose();
    },
  },

  created() {

  },

  mounted() {
    this.dropTypeItems = this.param.selBoxItems;
    this.dropItemsV    = this.param.popupDetails.INQRY_TYPE;
    if(this.param.childFlag == 'child') this.txtFieldV = this.param.popupDetails.INQRY_TYP_NM;
    if(this.param.childFlag == 'area') this.txtAreaV  = this.param.popupDetails.msg;
  },

  computed: {

  },
};

</script>

<style></style>
